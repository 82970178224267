/* ==============================================
   Buttons
============================================== */

.btn {
  display: inline-block;
  vertical-align: middle;
  padding: 12px 26px;
  border: 0;
  background: $color-complimentary;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 2px;
  color: #fff;
  text-transform: uppercase;
  cursor: pointer;
  border-radius: 25px;
  transition: background .3s ease;

  [data-interface='mouse'] &:hover {
    background: #da5058;
  }

  &:active {
    transform: translate(0, 1px);
  }
}

.btn--lrg {
  padding: 16px 36px;

  @include breakpoint('tablet-up') {
    padding: 17px 40px;
    font-size: 15px;
  }
}

.btn--bare {
  display: inline-block;
  vertical-align: middle;
  padding: 9px 22px;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 2px;
  // color: $color-complimentary;
  color: $base-color;
  text-transform: uppercase;
  cursor: pointer;

  .btn__label {
    display: inline-block;
    vertical-align: bottom; // NOTE: Cross-browser consistent spacing
    position: relative;
    top: 4px;
    padding: 0 0 5px;
    overflow: hidden;
    
    &:before {
      content: '';
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 2px;
      background: $color-complimentary;
      transform: translate(-102%, 0);
      transition: transform .3s ease;
    }
  }

  [data-interface='mouse'] &:hover .btn__label:before {
    transform: translate(0, 0);
  }
}

.btn--arrow {
  padding-left: 0;  
  color: $color-complimentary;

  .svg {
    display: inline-block;
    vertical-align: bottom; // NOTE: Cross-browser consistent spacing
    position: relative;
    top: -4px;
    margin: 0 0 0 4px;
  }

  .svg__fill {
    fill: $color-complimentary;
  }
}

// NOTE: Not using show helper because of display property
.btn--mobile {
  display: none;

  @include breakpoint('mobile-down') {
    display: inline-block;
  }
}

.btn--tablet {
  display: none;

  @include breakpoint('tablet-down') {
    display: inline-block;
  }
}
