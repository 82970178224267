/* ==============================================
   Components
============================================== */


.ff-title {
  font-family: $font-family;
}

.ff-body {
  font-family: $body-family;
  font-weight: 400;
}

.t--sm {
  font-size: 14px;
}

.c--body,
.c--gray-7 {
  color: $color-gray-7;
}

.c--gray-b {
  color: $color-gray-b;
}

.c--error {
  color: $color-error;
}

.Body {
  @extend .ff-body;
  @extend .c--body;
  line-height: 1.6;
}

.BodyLarge {
  @extend .ff-body;
  @extend .c--body;
  font-size: 24px;
  line-height: 1.5;
}

.Button {
  @extend .ff-title;
  display: inline-block;
  vertical-align: middle;
  padding: 9px 26px 10px;
  // Consistent spacing with wired buttons
  border: 1px solid $button-bg;
  background: $button-bg;
  font-size: 19px;
  font-weight: 600;
  line-height: 1;
  letter-spacing: .3px;
  color: #fff;
  white-space: nowrap;
  cursor: pointer;
  border-radius: 25px;
  transition: all .2s ease;
  user-select: none;
  
  &:hover {
    background: $button-bg-hover;
  }

  &:focus {
    outline: none;
  }

  &:active {
    background: $button-bg-down;
    outline: none;
  }

  &[disabled] {
    border-color: $button-bg-disabled;
    background: $button-bg-disabled;
    cursor: default;
  }

  .Icon--external {
    margin: -3px 7px 0 0;
    pointer-events: none;
  }
}

.Button--wide {
  min-width: 190px;
  padding-right: 40px;
  padding-left: 40px;
}

/* Loading
------------------------------------ */
.Button.is-loading {
  padding-right: 46px;
  border-color: $button-bg-disabled;
  background: $button-bg-disabled;
  cursor: default;
}

.Button__loader {
  position: relative;
  
  &:after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 4px;
    right: -26px;
    width: 12px;
    height: 12px;
    border: 2px solid rgba(255, 255, 255, .8);
    border-right-color: transparent;
    opacity: 0;
    border-radius: 50%;
    transition: opacity .1s ease, border .1s ease; 
    mask-image: linear-gradient(to bottom right, #000, rgba(0, 0, 0, 0));
  }

  .Button.is-loading & {
    &:after {
      opacity: 1;
      animation: rotate .7s linear infinite;
      transition-delay: .1s;
    }
  }
}

@keyframes rotate {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}


/* Links
------------------------------------ */
.Link {
  color: $color-link;
  cursor: pointer;
  transition: color .2s ease;
  user-select: none;
}

.Link--subtle {
  border-bottom: 1px solid;
  color: inherit;
  cursor: pointer;
  transition: all .2s ease;

  &:hover {
    color: $color-link;
  }
}

/* Headers
------------------------------------ */
.alpha {
  font-size: 46px;
  font-weight: 600;
}

.beta {
  font-size: 30px;
  font-weight: 600;
}

.gamma {
  font-size: 24px;
  font-weight: 600;
}

.delta {
  font-size: 20px;
  font-weight: 600;
}

.Input {
  @extend .ff-body;
  display: block;
  width: 100%;
  padding: 12px 15px;
  border: 1px solid $color-gray-f;
  background: $color-gray-f;
  box-shadow: none;
  font-size: 19px;
  border-radius: $radius;
  transition: all .3s ease;
  appearance: none;
  
  &:hover {
    border-color: rgba(212, 212, 212, .45);
    background: rgba(249, 249, 251, .69);
  }

  &:focus {
    border-color: $color-green;
    background: #fff;
    cursor: text;
    outline: 0;
  }
  
  &[readonly] {
    color: $color-gray-a;
  }

  &[readonly]:hover,
  &[readonly]:focus, {
    border-color: $color-gray-f;
    background: $color-gray-f;
    cursor: text;
  }

  
  // HACK: GJ Safari, adding a visual element to inputs
  &::-webkit-contacts-auto-fill-button {
    // scss-lint:disable ImportantRule
    display: none !important;
    visibility: hidden;
    pointer-events: none;
  }

  &.is-error {
    border-color: $color-error;
  }
}
