/* ==============================================
   Header Simple
============================================== */

.header-simple {
  padding-top: 40px;

  @include breakpoint('tablet-up') {
    padding-top: 60px;
  }
}

.logo {
  display: block;
  width: 120px;
  height: 28px;

  @include breakpoint('tablet-up') {
    width: 146px;
    height: 35px;
  }
}
