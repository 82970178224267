/* ==============================================
   Admin Page
============================================== */

.auth-content {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  padding-top: 60px;
  padding-bottom: 100px;
}

.auth-footer {
  padding-bottom: 40px;

  @media (min-height: 800px) {
    padding-bottom: 60px;
  }
}

.auth-aside {
  @media (min-width: 1000px) {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    width: 40%;
    background: #ffdfdf image-url('auth/aside-ice-cream.jpg') no-repeat top right;
    background-size: cover;

    &:before {
      content: '';
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 2;
      width: 100%;
      height: 80%;
      background-image: linear-gradient(180deg, rgba(240,120,127,0.00) 0%, #F0787F 100%);
    }
  }
}

.stagger-item {
  will-change: opacity, transform;
}

.error-msg {
  @extend .ff-body;
  display: none;
  margin-top: 8px;
  line-height: 1.4;

  &.is-visible {
    display: block;
  }
}

.form {
  max-width: 470px;
}
