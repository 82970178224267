/* ==============================================
   Containers
============================================== */

.container {
  padding: 0 $page-gutter-mobile;

  @include breakpoint('tablet-only') {
    padding: 0 $page-gutter-tablet;
  }

  @include breakpoint('desktop-up') {
    max-width: 1600px;
    width: 86%;
    margin: 0 auto;
    padding: 0;
  }
}

// Container size variations
.container--lrg {
  max-width: $container-widescreen;
}

.container--med {
  max-width: $container-desktop;
}

.container--small {
  max-width: $container-tablet;
}

.container--micro {
  max-width: $container-mobile;
}
