/* ==============================================
   Forms
============================================== */

// form {
//   max-width: $content-width - ($content-gutter * 2);
//   transition: opacity .3s ease;

//   @include breakpoint('desktop-up') {
//     max-width: $content-width - ($content-gutter-lrg * 2);
//   }
// }

// scss-lint:disable VendorPrefix
::-webkit-input-placeholder {color: $color-placeholder;}
:-moz-placeholder {color: $color-placeholder;}
::-moz-placeholder {color: $color-placeholder;}
:-ms-input-placeholder {color: $color-placeholder;}

/* Inputs
------------------------------------ */
.input {
  display: block;
  width: 100%;
  padding: 0 40px 6px 0;
  border: 0;
  border-bottom: 2px solid #d9d8d9;
  overflow: hidden;
  background: none;
  font-weight: 500;
  border-radius: 0;
  transition: border .4s ease;
  text-overflow: ellipsis;
  
  &:focus {
    // border-color: $color-complimentary;
    outline: 0;
  }
}

.input--txt-btn {
  padding-right: 69px;
}


.submit {
  display: block;
  position: absolute;
  right: -8px;
  bottom: 1px;
  z-index: $z1;
  padding: 5px 10px;
  border: 0;
  background: none;
  cursor: pointer;

  &:focus {
    outline: 0;
  }
}

.loading {
  content: '';
  display: block;
  position: absolute;
  top: 7px; // NOTE: Magic number offsetting the arrow button
  right: 11px; // NOTE: Magic number offsetting the arrow button
  width: 16px;
  height: 16px;
  border: 2px solid #999;
  border-right-color: transparent;
  opacity: 0;
  border-radius: 50%;
  transition: opacity .1s ease;
  mask-image: linear-gradient(to bottom right, #000, rgba(0, 0, 0, 0));

  .is-loading & {
    opacity: 1;
    animation: rotate .7s linear infinite;
    transition-delay: .1s;
  }
}

@keyframes rotate {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
