/* ==============================================
   Collaborate
============================================== */

.collaborator-form {
  max-width: 670px;

  .alpha {
    max-width: 550px;
  }
}

.collaborator-form--offset-bottom {
  padding-bottom: 90px; 
}

.collaborator-footnote {
  max-width: 500px;
}