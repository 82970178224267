/* ==============================================
   Fade
============================================== */

/* Entering
------------------------------------ */
.is-fade-enter-active {
  .page-transition-screen {
    width: 100%;
    height: 100%;
    opacity: 1;
  }
}

.is-fade-entering {
  .page-transition-screen {
    opacity: 0;
  }

  .header {
    opacity: 1;
    transform: translate(0, 0);
    transition: all .7s ease .1s;
  }
}


/* Exiting
------------------------------------ */
.is-fade-exit-active {
  .page-transition-screen {
    width: 100%;
    height: 100%;
  }
}

.is-fade-exiting {
  .page-transition-screen {
    opacity: 1;
    transition-duration: .7s;
  }
}
