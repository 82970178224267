/* ==============================================
   Transitions
============================================== */

.page-transition-screen {
  position: fixed;
  top: 0;
  left: 0;
  // NOTE: Lower than header
  z-index: $z4 - 1;
  background: #fff;
  opacity: 0;
  will-change: opacity;
  transition: opacity .8s ease;
  pointer-events: none;
}
