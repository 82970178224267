/* ==============================================
   Calibre
============================================== */

// Regular
@font-face {
  font-family: 'Calibre';
  src: url('/fonts/calibre/CalibreWeb-Regular.woff2') format('woff2'),
       url('/fonts/calibre/CalibreWeb-Regular.woff') format('woff');
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}

// Regular Italic
@font-face {
  font-family: 'Calibre';
  src: url('/fonts/calibre/CalibreWeb-RegularItalic.woff2') format('woff2'),
       url('/fonts/calibre/CalibreWeb-RegularItalic.woff') format('woff');
  font-style: italic;
  font-weight: 400;
  font-display: swap;
}

// Medium
@font-face {
  font-family: 'Calibre';
  src: url('/fonts/calibre/CalibreWeb-Medium.woff2') format('woff2'),
       url('/fonts/calibre/CalibreWeb-Medium.woff') format('woff');
  font-style: normal;
  font-weight: 500;
  font-display: swap;
}

// Medium Italic
@font-face {
  font-family: 'Calibre';
  src: url('/fonts/calibre/CalibreWeb-MediumItalic.woff2') format('woff2'),
       url('/fonts/calibre/CalibreWeb-MediumItalic.woff') format('woff');
  font-style: italic;
  font-weight: 500;
  font-display: swap;
}

// Semibold
@font-face {
  font-family: 'Calibre';
  src: url('/fonts/calibre/CalibreWeb-Semibold.woff2') format('woff2'),
       url('/fonts/calibre/CalibreWeb-Semibold.woff') format('woff');
  font-style: normal;
  font-weight: 600;
  font-display: swap;
}

// Semibold Italic
@font-face {
  font-family: 'Calibre';
  src: url('/fonts/calibre/CalibreWeb-SemiboldItalic.woff2') format('woff2'),
       url('/fonts/calibre/CalibreWeb-SemiboldItalic.woff') format('woff');
  font-style: italic;
  font-weight: 600;
  font-display: swap;
}
