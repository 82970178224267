/* ==============================================
   Founders Grotesk
============================================== */

// Light
@font-face {
  font-family: 'Founders Grotesk';
  src: url('/fonts/founders-grotesk/founders-grotesk-web-light.woff2') format('woff2'),
       url('/fonts/founders-grotesk/founders-grotesk-web-light.woff') format('woff');
  font-style: normal;
  font-weight: 300;
  font-display: swap;
}

// Light Italic
@font-face {
  font-family: 'Founders Grotesk';
  src: url('/fonts/founders-grotesk/founders-grotesk-web-light-italic.woff2') format('woff2'),
       url('/fonts/founders-grotesk/founders-grotesk-web-light-italic.woff') format('woff');
  font-style: italic;
  font-weight: 300;
  font-display: swap;
}

// Regular
@font-face {
  font-family: 'Founders Grotesk';
  src: url('/fonts/founders-grotesk/founders-grotesk-web-regular.woff2') format('woff2'),
       url('/fonts/founders-grotesk/founders-grotesk-web-regular.woff') format('woff');
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}

// Regular Italic
@font-face {
  font-family: 'Founders Grotesk';
  src: url('/fonts/founders-grotesk/founders-grotesk-web-regular-italic.woff2') format('woff2'),
       url('/fonts/founders-grotesk/founders-grotesk-web-regular-italic.woff') format('woff');
  font-style: italic;
  font-weight: 400;
  font-display: swap;
}

// Medium
@font-face {
  font-family: 'Founders Grotesk';
  src: url('/fonts/founders-grotesk/founders-grotesk-web-medium.woff2') format('woff2'),
       url('/fonts/founders-grotesk/founders-grotesk-web-medium.woff') format('woff');
  font-style: normal;
  font-weight: 500;
  font-display: swap;
}

// Medium Italic
@font-face {
  font-family: 'Founders Grotesk';
  src: url('/fonts/founders-grotesk/founders-grotesk-web-medium-italic.woff2') format('woff2'),
       url('/fonts/founders-grotesk/founders-grotesk-web-medium-italic.woff') format('woff');
  font-style: italic;
  font-weight: 500;
  font-display: swap;
}

// Semibold
@font-face {
  font-family: 'Founders Grotesk';
  src: url('/fonts/founders-grotesk/founders-grotesk-web-semibold.woff2') format('woff2'),
       url('/fonts/founders-grotesk/founders-grotesk-web-semibold.woff') format('woff');
  font-style: normal;
  font-weight: 600;
  font-display: swap;
}

// Semibold Italic
@font-face {
  font-family: 'Founders Grotesk';
  src: url('/fonts/founders-grotesk/founders-grotesk-web-semibold-italic.woff2') format('woff2'),
       url('/fonts/founders-grotesk/founders-grotesk-web-semibold-italic.woff') format('woff');
  font-style: italic;
  font-weight: 600;
  font-display: swap;
}
