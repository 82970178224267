/* ==============================================
   Intrinsic
============================================== */

.intrinsic {
  position: relative;
}

.intrinsic__img {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}
