/* ==============================================
   Refresh
============================================== */

/**
 * A refreshing concoction of reset, normalize, and defacto style suggestions
 * that create solid footing for your site.
 * Contents generously influenced by Eric Meyer's reset.css and Normalize (git.io/normalize)
 */


/**
 * Whip the box model into shape with border-box!
 * http://paulirish.com/2012/box-sizing-border-box-ftw/
 */
* {
  box-sizing: border-box;
}

/**
 * General margin, padding, and border resets.
 */
// scss-lint:disable SingleLinePerSelector
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
}

/*
 * [1] - Set default font family to sans-serif.
 * [2] - Prevent iOS text size adjust after orientation change, without disabling user zoom.
 *
 */
html {
  font-size: 100%;                  /* [1] */
  text-size-adjust: 100%;           /* [2] */
}

/**
 * Correct `block` display not defined in IE 8/9.
 */
// scss-lint:disable SingleLinePerSelector
article, aside, details, figcaption, figure, footer, header, hgroup, nav, section, summary {
  display: block;
}

/**
 * Correct `inline-block` display not defined in IE 8/9.
 */
audio,
canvas,
video {
  display: inline-block;
}

/**
 * Prevent modern browsers from displaying `audio` without controls.
 * Remove excess height in iOS 5 devices.
 */
audio:not([controls]) {
  display: none;
  height: 0;
}

/**
 * Remove default list-style from list elements.
 */
ul,
ol {
  list-style: none;
}

/**
 * Improve readability when focused and also mouse hovered in all browsers.
 */
a:active,
a:hover {
  outline: 0;
}

/**
 * [1] - Remove border when inside `a` element in IE 8/9.
 * [2] - Improve IE's image resizing
 */
img {
  border: 0;                        /* [1] */
  -ms-interpolation-mode: bicubic;  /* [2] */
}

/**
 * Correct overflow displayed oddly in IE 9.
 */
svg:not(:root) {
  overflow: hidden;
}

/**
 * Correct font family set oddly in Safari 5 and Chrome.
 */
code,
kbd,
pre,
samp {
  font-family: monospace, serif;
}

/**
 * Improve readability of pre-formatted text in all browsers.
 */
pre {
  white-space: pre-wrap;
}

q {
  quotes: none;

  &:before,
  &:after {
    content: '';
  }
}

/**
 * Prevent `sub` and `sup` affecting `line-height` in all browsers.
 */
sub,
sup {
  vertical-align: baseline;
  position: relative;
  font-size: 75%;
  line-height: 0;
}

sup {
  top: -.5em;
}

sub {
  bottom: -.25em;
}

/**
 * [1] - Address margins set differently in Firefox 4+, Safari 5, and Chrome.
 * [2] - Correct font family not being inherited in all browsers.
 * [3] - Correct font size not being inherited in all browsers.
 */
button,
input,
select,
textarea {
  margin: 0;              /* [1] */
  font-family: inherit;   /* [2] */
  font-size: 100%;        /* [3] */
}

/**
 * Address Firefox 4+ setting `line-height` on `input` using `!important` in
 * the UA stylesheet.
 */
button,
input {
  line-height: normal;
}

/**
 * Remove inner padding and border in Firefox 4+.
 */
button::-moz-focus-inner,
input::-moz-focus-inner {
  padding: 0;
  border: 0;
}

/**
 * [1] - Improve readability and alignment in all browsers.
 * [2] - Remove default vertical scrollbar in IE 8/9.
 * [3] - Restrict horizontal resizing.
 */
textarea {
  vertical-align: top;  /* [1] */
  overflow: auto;       /* [2] */
  resize: vertical;     /* [3] */
}

/**
 * Make all labels appear clickable. Labels should include the
 * `for` attribute to allow access to the field they represent.
 */
label {
  cursor: pointer;
}

/**
 * Remove most spacing between table cells.
 */
table {
  border-collapse: collapse;
  border-spacing: 0;
}
