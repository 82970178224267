/* ==============================================
   Utilities
============================================== */

/* Position
------------------------------------ */
.af {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.rel {
  position: relative;
}

.ib {
  display: inline-block;
}

/* Margin
------------------------------------ */
.mt {
  margin-top: 20px;
}

.mt--sm {
  margin-top: 14px;
}

.mt--xs {
  margin-top: 10px;
}

.mt--med {
  margin-top: 30px;
}

.mt--lrg {
  margin-top: 50px;
}

.mt--xl {
  margin-top: 70px;
}

.mb {
  margin-bottom: 20px;
}

.mb--xs {
  margin-bottom: 8px;
}

.mb--sm {
  margin-bottom: 14px;
}

.mb--med {
  margin-bottom: 30px;
}

.mb--lrg {
  margin-bottom: 50px;
}

.mb--xl {
  margin-bottom: 60px;
}

.mac {
  margin-right: auto;
  margin-left: auto;
}


/* Alignment
------------------------------------ */
.tar {
  text-align: right;
}

.tac {
  text-align: center;
}

.nwr {
  white-space: nowrap;
}


/* Visuals
------------------------------------ */
.ds {
  box-shadow: 0 5px 40px rgba(0, 0, 0, .1);
}

.dss {
  box-shadow: 0 5px 40px rgba(0, 0, 0, .2);
}

.rnd {
  border-radius: $radius-lrg;
}

.rnd--ios {
  border-radius: 12px;

  @include breakpoint('desktop-up') {
    border-radius: 20px;
  }
}


/* Pulls
------------------------------------ */
.pr {
  @include breakpoint('mobile-down') {
    margin-right: $page-gutter-mobile * -1;
  }

  @include breakpoint('tablet-only') {
    margin-right: $page-gutter-tablet * -1;
  }
}

.pl {
  @include breakpoint('mobile-down') {
    margin-left: $page-gutter-mobile * -1;
  }

  @include breakpoint('tablet-only') {
    margin-left: $page-gutter-tablet * -1;
  }
}

.z0 {
  z-index: -1;
}

.z1 {
  z-index: $z1;
}
