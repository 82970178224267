/* ==============================================
   Login
============================================== */

.login-step {
  display: none;
  float: left;
  max-width: 550px;
  width: 100%;
  margin-right: -100%;

  &.is-active {
    display: block;
  }
}

.load-error-message {
  display: none;

  &.is-visible {
    display: block;
  }
}
